import React, { Fragment, PureComponent } from 'react'
import { Jumbotron, OpeningHours, Layout } from '../../components'
import '../style.less'

class FindUs extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  iframe () {
    return {
      __html: '<iframe width="100%" height="450" frameBorder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=woolpack%20iwade&key=AIzaSyBGKad9GVkzFBGgfglrKD20OTkSvWbxVp0&" allowFullScreen></iframe>'
    }
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Find Us'}
          location={this.props.location}
        >
          <Jumbotron
            image={'findUs.jpg'}
            imageAlt={'Out side view of the woolpack'}
          />
          <div className='container'>
            <div className='row'>
              <OpeningHours />
              <div className='col-md-8 col-xs-12'>
                <h2 className='no-bottom para-h2'>How to find us</h2>
                <p className='mb-4'>
                  The Woolpack pub is located on The Street, in the village of Iwade, near Sittingbourne. The A249 and the A2 are close by and the M2 is ten minutes away. For all enquiries, please contact the pub on 01795 472656.
                </p>
                <div className='maps-container'>
                  <div dangerouslySetInnerHTML={this.iframe()} />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Fragment >
    )
  }
}

export default FindUs
